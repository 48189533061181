import { useState, useEffect } from 'react'

import NowPlaying from './NowPlaying'
import SearchBar from './SearchBar'
import DigitalShelf from './DigitalShelf'
import Recommendations from './Recommendations'

const LoggedInApp = ({ user, userLoading }) => {
  const [shelf, setShelf] = useState([])
  const [albumDetails, setAlbumDetails] = useState([])
  const [recsShown, setRecsShown] = useState(false)

  useEffect(() => {
    if (user) {
      setShelf(user.albums)
      if (!user.albums.length) setRecsShown(true)
    }
  }, [user])

  useEffect(() => {
    const fetchAlbumDetails = async () => {
      try {
        const response = await fetch(`/api/albums?ids=${shelf.join(',')}`)
        const data = await response.json()
        setAlbumDetails(data)
      } catch (error) {
        console.error('Failed to fetch album details', error)
      }
    }

    if (shelf.length) {
      fetchAlbumDetails()
    }
  }, [shelf])

  const handleAddToShelf = async (albumId) => {
    const response = await fetch(`/api/user/albums`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ albumId }),
    })
    const data = await response.json()
    if (data.albums) setShelf(data.albums)
  }

  const deleteFromShelf = async (albumId) => {
    const response = await fetch(`/api/user/albums/${albumId}`, {
      method: 'DELETE',
    })
    const data = await response.json()
    if (data.albums) setShelf(data.albums)
  }

  return (
    <>
      <NowPlaying />
      <main>
        <SearchBar onAddToShelf={handleAddToShelf} />
        <DigitalShelf
          shelf={shelf}
          albumDetails={albumDetails}
          deleteFromShelf={deleteFromShelf}
        />
        <button
          className="m-2 p-4 bg-emerald-600 rounded-lg text-white"
          onClick={() => setRecsShown(!recsShown)}
        >
          {recsShown ? 'Hide' : 'Show'} Recommendations
        </button>
        <Recommendations show={recsShown} handleAddToShelf={handleAddToShelf} />
      </main>
    </>
  )
}

export default LoggedInApp
