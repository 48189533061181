import React, { useState, useEffect, useRef } from 'react'

const SearchBar = ({ onAddToShelf }) => {
  const [query, setQuery] = useState('')
  const [results, setResults] = useState([])
  const currentResultTime = useRef(0)

  useEffect(() => {
    if (query) {
      const timestamp = Date.now()
      fetch(`/api/search?q=${query}`)
        .then((response) => response.json())
        .then((data) => {
          if (timestamp > currentResultTime.current) {
            setResults(data.albums.items.slice(0, 3))
            currentResultTime.current = timestamp
          }
        })
    } else {
      setResults([])
      currentResultTime.current = Date.now()
    }
  }, [query])

  const handleAlbumClick = (album) => {
    onAddToShelf(album.id)
    setQuery('')
  }

  return (
    <div className="search-bar relative z-10 p-4 mb-4">
      <input
        type="search"
        className="p-2 border rounded w-full"
        placeholder="Search for an album..."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      {results.length > 0 && (
        <div className="absolute top-full mt-2 w-full bg-white border rounded shadow-lg">
          {results.map((album) => (
            <div
              key={album.id}
              className="flex items-center p-2 hover:bg-gray-200 cursor-pointer"
              onClick={() => handleAlbumClick(album)}
            >
              <img
                className="h-24 w-24 mr-2"
                src={album.images[1]?.url}
                alt={album.name}
              />
              <div>
                <h2 className="text-sm">{album.name}</h2>
                <p className="text-xs text-gray-600">{album.artists[0].name}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default SearchBar
