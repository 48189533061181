import React, { useEffect, useState } from 'react'

import { PlayingStateProvider } from './PlayingState'

import NavBar from './NavBar'
import Login from './Login'
import LoggedInApp from './LoggedInApp'
import './App.css'

function useCurrentUser() {
  const [user, setUser] = useState(null)
  const [userLoading, setUserLoading] = useState(true)

  useEffect(() => {
    async function fetchUser() {
      try {
        const response = await fetch('/auth/user')
        const data = await response.json()
        if (data.user) {
          setUser(data.user)
        }
        setUserLoading(false)
      } catch (error) {
        console.error('Failed to fetch user', error)
      }
    }
    fetchUser()
  }, [])

  return { user, userLoading }
}

function App() {
  const { user, userLoading } = useCurrentUser()

  return (
    <div className="App">
      <PlayingStateProvider>
        <NavBar user={user} userLoading={userLoading} />
        {!user && <Login />}
        {user && !userLoading && (
          <LoggedInApp user={user} userLoading={userLoading} />
        )}
      </PlayingStateProvider>
    </div>
  )
}

export default App
