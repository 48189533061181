import React, { useState } from 'react'

import { usePlayingState } from './PlayingState'

import RecordIcon from './RecordIcon'

const playAlbum = (albumId) => {
  fetch(`/api/play/${albumId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ albumId }),
  })
}

const DigitalShelf = ({ shelf, albumDetails, deleteFromShelf }) => {
  const { isPlaying } = usePlayingState()
  const [editing, setEditing] = useState(false)
  const [playingAlbum, setPlayingAlbum] = useState(null)

  const handleAlbumClick = (albumId) => {
    try {
      if (isPlaying) {
        setPlayingAlbum(albumId)
        playAlbum(albumId)
      } else {
        window.open(`https://open.spotify.com/album/${albumId}`, '_blank')
      }
    } catch (error) {
      console.error('Error playing album:', error)
    }
  }

  return (
    <section>
      <div className="w-full flex flex-row justify-end">
        <button
          className="m-2 py-2 w-16 bg-emerald-600 rounded-lg text-white"
          onClick={() => {
            setEditing(!editing)
          }}
        >
          {editing ? 'done' : 'edit'}
        </button>
      </div>
      <div className="grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-0">
        {(albumDetails || []).map((album) => {
          return (
            <Record
              key={album.id}
              id={album.id}
              title={album.name}
              image={album.images[0].url}
              artist={album.artists[0].name}
              year={album.release_date?.substring(0, 4)}
              isPlaying={playingAlbum === album.id}
              editing={editing}
              onPlay={handleAlbumClick}
              deleteFromShelf={deleteFromShelf}
            />
          )
        })}
      </div>
    </section>
  )
}

const Record = ({
  id,
  title,
  image,
  artist,
  year,
  isPlaying,
  editing,
  onPlay,
  deleteFromShelf,
}) => {
  return (
    <div
      className={`album-item ${isPlaying ? 'is-playing' : ''}`}
      {...(!editing && { onClick: () => onPlay(id) })}
    >
      <img className="cover-image w-full" src={image} alt={title} />
      <RecordIcon className="text-black record w-full h-full" />
      {editing && (
        <div className="absolute bg-black/70 top-0 inset-0 p-4 flex flex-col justify-center items-center">
          <button
            className="absolute top-0 right-1 p-2 text-xl text-white"
            onClick={() => deleteFromShelf(id)}
          >
            ×
          </button>
          <h2 className="w-full text-center text-xl text-white text-ellipsis min-w-0 whitespace-nowrap overflow-hidden">
            {title}
          </h2>
          <p className="w-full text-center text-white">{artist}</p>
          <p className="w-full text-center text-white">{year}</p>
        </div>
      )}
    </div>
  )
}

export default DigitalShelf
