import React, { useState, useEffect } from 'react'

const Recommendations = ({ show, handleAddToShelf }) => {
  const [albums, setAlbums] = useState([])
  const [selectedAlbums, setSelectedAlbums] = useState([])

  useEffect(() => {
    fetch('/api/recommendations')
      .then((response) => response.json())
      .then((data) => {
        setAlbums(data.albums)
      })
  }, [])

  const handleAddAlbum = (albumId) => {
    setSelectedAlbums([...selectedAlbums, albumId])
    handleAddToShelf(albumId)
  }

  if (!show) return null

  return (
    <>
      <h2 className="text-xl font-bold px-2">Some ideas for you!</h2>
      <div className="grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-0">
        {albums.map((album) => (
          <div key={album.id} className="relative">
            <img
              className="w-full"
              src={album.images[0].url}
              alt={album.name}
            />
            <div className="p-2">
              <h2 className="text-xl font-medium mb-1 whitespace-nowrap overflow-hidden text-ellipsis">
                {album.name}
              </h2>
              <h3 className="text-lg text-gray-600 mb-2">
                {album.artists[0].name}
              </h3>
              <button
                className="absolute top-2 right-2 z-10 bg-emerald-600 text-white text-lg font-bold rounded-full h-10 w-10 flex items-center justify-center"
                onClick={() => handleAddAlbum(album.id)}
                disabled={selectedAlbums.includes(album.id)}
              >
                {selectedAlbums.includes(album.id) ? '✓' : '+'}
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default Recommendations
