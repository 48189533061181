import React, { createContext, useContext, useState, useEffect } from 'react'

const PlayingStateContext = createContext()

export const PlayingStateProvider = ({ children }) => {
  const [everPlayed, setEverPlayed] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [currentPlay, setCurrentPlay] = useState({})

  const getNowPlaying = async () => {
    const response = await fetch('/api/playing')
    const data = await response.json()
    if (data.is_playing && data.item) {
      setIsPlaying(true)
      setCurrentPlay(data.item)
    } else {
      setIsPlaying(false)
    }
  }

  useEffect(() => {
    const interval = setInterval(getNowPlaying, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (isPlaying) setEverPlayed(true)
  }, [isPlaying])

  const togglePause = () => {
    setIsPlaying(!isPlaying)
  }

  const contextValue = {
    everPlayed,
    isPlaying,
    currentPlay,
    togglePause,
  }

  return (
    <PlayingStateContext.Provider value={contextValue}>
      {children}
    </PlayingStateContext.Provider>
  )
}

export const usePlayingState = () => {
  const context = useContext(PlayingStateContext)
  if (!context) {
    throw new Error(
      'usePlayingState must be used within a PlayingStateProvider'
    )
  }
  return context
}
