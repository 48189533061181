import React, { useState, useEffect, useRef } from 'react'

import RecordIcon from './RecordIcon'
import { usePlayingState } from './PlayingState'

const NowPlaying = () => {
  const { everPlayed, isPlaying, currentPlay, togglePause } = usePlayingState()

  return (
    <div
      className={`now-playing z-20 left-0 w-full bg-zinc-800 text-white flex flex-col items-center ${
        everPlayed ? 'top-0' : 'now-playing-hidden'
      }`}
    >
      <img
        src={currentPlay.album?.images && currentPlay.album?.images[0]?.url}
        alt="Album artwork"
        className="w-4/5 mx-auto my-8"
      />
      <div className="flex flex-row w-4/5 mb-8">
        <div
          className={`self-center w-16 h-16 mr-4 shrink-0`}
          onClick={togglePause}
        >
          <RecordIcon
            className={`w-16 h-16 ${isPlaying ? 'animate-spin-slow' : ''}`}
          />
        </div>
        <div className="p-2 min-w-0 flex-grow  ">
          <div className="text-sm font-bold overflow-hidden whitespace-nowrap text-ellipsis">
            {currentPlay.album?.name}
          </div>
          <div className="text-sm italic overflow-hidden whitespace-nowrap text-ellipsis">
            {currentPlay.name}
          </div>
          <div className="text-sm overflow-hidden whitespace-nowrap text-ellipsis">
            {currentPlay.artists && currentPlay.artists[0].name}
          </div>
          <div className="text-xs text-gray-400">
            {currentPlay.album?.release_date?.substring(0, 4)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default NowPlaying
