import React, { useState } from 'react'

const LoginButton = () => {
  const [loading, setLoading] = useState(false)

  const onClick = () => setLoading(true)

  return (
    <a
      href="/auth/login"
      type="button"
      className="bg-emerald-600 text-white text-xl rounded-lg w-full h-20 flex items-center justify-center"
      disabled={loading}
      onClick={onClick}
    >
      <span className={(loading && 'animate-bounce') || ''}>
        {loading ? '•••••' : 'Connect Spotify to Begin'}
      </span>
    </a>
  )
}

const NavBar = ({ user, userLoading }) => {
  return (
    <div className="navbar h-20 p-4 w-full flex justify-between">
      {userLoading ? (
        'Loading...'
      ) : user ? (
        <>
          <button className="bg-emerald-600 text-white font-bold rounded-lg px-4 py-2">
            VinylVibe
          </button>
          <a href="/auth/logout" className="text-sm">
            <img
              src={user.imageUrl}
              alt={user.displayName}
              className="rounded-full w-12 h-12"
            />
          </a>
        </>
      ) : (
        <LoginButton />
      )}
    </div>
  )
}

export default NavBar
